/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

.mdc-text-field--filled.mdc-text-field--disabled .mdc-text-field__input {
    color: rgba(0, 0, 0, 0.8) !important;
}

.mat-mdc-select-disabled {
    color: rgba(0, 0, 0, 0.8) !important;
}

.mat-mdc-paginator .mat-mdc-paginator-container {
    padding: 0;
}
